<template>
    <div>
        <div class="gavc-tooltip">
            <span class="gavc-tooltip-icon">
                <i class="gavc-icon gavc-icon-question" ></i>
            </span>
            <span class="gavc-p gavc-text-martinique"> 
                Besoin d'aide ? Contactez-nous directement à l'adresse 
                <a href="mailto:contact@garance-a-vos-cotes.fr">
                contact@garance-a-vos-cotes.fr
                </a>
            </span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'besoinAideComponent',
}
</script>